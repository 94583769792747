<template>
  <breadcrumbs />
</template>

<script>
export default {
  name: "Breadcrumb"
}
</script>

<style>
#m_header_menu {
  display: table;
}
.breadcrumb {
  background: none;
  display: table-cell;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  padding-left: 5px;
}
.breadcrumb-item {
  display: inline-block;
}
</style>
